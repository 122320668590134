<template>
    <div class="main">

        <div class="map-search">
            <div class="w12 flex">
                <img class="map-search-icon" src="../assets/img/main/house-search.png" />
                <div class="map-search-input">
                    <el-input placeholder="输入仓库关键词搜索" v-model="searchData">
                        <el-button slot="append" icon="el-icon-search" @click="goToMap"></el-button>
                    </el-input>
                </div>
                <div class="box">
                    <div>
                        <router-link class="nav-title" to="/map">地图找库</router-link>
                    </div>
                    <img src="../assets/img/main/house-down.png" alt="" class="down" />
                </div>
                <el-form class="form" ref="form" :model="form">
                    <div class="form-title">仓库所在区域</div>
                    <!-- <el-form-item>
            <el-select
              v-model="form.province"
              style="width: 290px"
              placeholder="选择省"
              @change="changeCity"
            >
              <el-option
                :value="it"
                v-for="(it, ind) in form.provinceOp"
                :key="ind"
                :label="it.name"
              ></el-option>
            </el-select>
          </el-form-item> -->

                    <!-- <el-cascader
            class="map-nav-input"
            clearable
            size="large"
            :options="cascaderArry"
            v-model="selectedOptions"
            @change="changeCity"
            placeholder="请选择仓库所在区域"
          >
          </el-cascader> -->

                    <el-cascader class="map-nav-input" clearable size="large" v-model="selectedOptions"
                        :props="navInputProps" placeholder="请选择仓库所在区域">
                    </el-cascader>

                    <el-button class="btn" type="primary" icon="el-icon-search" @click="goToMap">搜索</el-button>
                </el-form>
            </div>
        </div>

        <!-- <div class="vr" v-if="wareHouseListVr.homeRecommendList &&
      wareHouseListVr.homeRecommendList.length > 0
      ">
      <div class="w12 w11">
        <img class="title" src="../assets/img/main/vr-title.png" />
        <div class="vr-swiper">
          <swiper :options="swiperOptionVR" ref="mySwiperVR" style="height: 622px">
            <swiper-slide class="slide-swiper" v-for="(it, ind) in wareHouseListVr.homeRecommendList" :key="ind">
              <card-t :vr="it.vrAddress ? true : false" :info="it"></card-t>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="more">
          <el-button type="primary" @click="goToMap"
            >更多 <i class="el-icon-arrow-right"></i
          ></el-button>
        </div>
      </div>
    </div> -->

        <div class="warehouse">
            <div class="w12">
                <div class="warehouse-top flex" style="margin-bottom: 50px">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane :label="item.recommendCategoryName" :name="index + ''"
                            v-for="(item, index) in wareHouseList" :key="item.recommendCategoryId"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="flexws-box" v-for="(item, index) in wareHouseList" :key="item.recommendCategoryId">
                    <div class="flexws warehouse-card" :class="preActiveNum <= index ? 'firstActive' : 'secondActive'"
                        v-if="activeName == index">
                        <Card-t style="margin-bottom: 44px" v-for="(it, k) in item.homeRecommendList" :key="k"
                            :info="it"></Card-t>
                    </div>
                </div>
                <div class="more" v-if="wareHouseList.length > 0">
                    <el-button type="primary" @click="goToMap">查看更多 </el-button>
                </div>



                <!-- <div
            class="flexws"
            :class="activeName == 'second' ? 'secondActive' : ''"
            v-if="
              activeName == 'second' &&
              wareHouseList.length == 2 &&
              wareHouseList[0].homeRecommendList.length > 0
            "
          >
            <Card-t
              style="margin-bottom: 44px"
              v-for="(it, k) in wareHouseList[0].homeRecommendList"
              :key="k"
              :info="it"
            ></Card-t>
          </div> -->
                <!-- <div class="more">
          <el-button type="primary" @click="goToMap('more')"
            >更多 <i class="el-icon-arrow-right"></i
          ></el-button>
        </div> -->
            </div>
        </div>
        <div class="warehouse">
            <div class="w12" v-if="list1.list.length > 0 || list2.list.length > 0">
                <div class="warehouse-top flex" style="margin-bottom: 50px">
                    <el-tabs v-model="tabCurrents" @tab-click="handleClick">
                        <el-tab-pane :label="item.name" :name="index + ''" v-for="(item, index) in tabList"
                            :key="index"></el-tab-pane>
                    </el-tabs>
                </div>
                <div class="flexws-box" v-for="(item, index) in tabList" :key="index">
                    <div class="flexws warehouse-card" :class="preActiveNum <= index ? 'firstActive' : 'secondActive'"
                        v-if="tabCurrents == index">
                        <!-- <Card-t style="margin-bottom: 44px" v-for="(it, k) in item.homeRecommendList" :key="k" :info="it"></Card-t> -->
                        <div class="listData" v-if="tabCurrents == 0 && list1.list.length > 0">
                          
                            <div class="everyss" v-for="(iten, inde) in list1.list" :key="inde">
                                <div class="every_list" @click="goRentDetail(iten.id)" v-if="inde < 6">

                                    <div class="every_content">
                                        <div class="list_title">
                                            {{ iten.title }}
                                        </div>
                                        <div class="list_content">
                                            <div class="everyContent">
                                                求租人：{{ iten.seekRenterName }}
                                            </div>
                                            <div class="everyContent">
                                                起租日期：{{ iten.rentBeginTime }}
                                            </div>
                                            <div class="everyContent ellipsis_1">
                                                仓库类型：<span class="everyContents"
                                                    v-for="(items, indexs) in iten.ypWarehouseTypeArr" :key="indexs">{{
                                                        items.itemName }}<span class="everyContents"
                                                        v-if="indexs < iten.ypWarehouseTypeArr.length - 1">、</span></span>
                                            </div>
                                            <!-- <div class="everyContent">
                                                求租面积：{{ iten.areaMin }}~{{ iten.areaMax }}㎡
                                            </div> -->
                                            <div class="everyContent" v-if="iten.areaMax != 100 && iten.areaMin != 500">
                                                求租面积：{{ iten.areaMin }}~{{ iten.areaMax }}㎡
                                            </div>
                                            <div class="everyContent" v-if="iten.areaMin == 500">
                                                求租面积：500㎡以上
                                            </div>
                                            <div class="everyContent" v-if="iten.areaMax == 100">
                                                求租面积：100㎡以下
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list_ico" v-if="inde < 6">
                                        <img src="@/assets/rightGreen.png" alt="">
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="listData" v-if="tabList.length == 2">
                            <div v-if="tabCurrents == 1 && list2.list.length > 0" class="listData">
                                <div class="everyss" v-for="(items, inde) in list2.list" :key="inde">
                                    <div class="every_list2" @click="goDetail(items.id)" v-if="inde < 6">
                                        <div class="list_title">
                                            <div class="title_content">
                                                {{ items.title }}
                                            </div>
                                            <div class="title_money">
                                                <span class="mianyi" v-if="items.isFace == 1">面议</span>
                                                <span class="wage" v-else>{{ items.salaryMin }}~{{ items.salaryMax
                                                }}k</span>
                                            </div>
                                        </div>
                                        <div class="jianjie" v-if="inde < 6">
                                            <div class="everyContent1 ellipsis_3">
                                                {{ items.description }}
                                            </div>
                                        </div>
                                        <div class="last_bottom" v-if="inde < 6">
                                            <div class="gongsi">
                                                {{ items.companyName }}
                                            </div>
                                            <div class="shijian">
                                                {{ items.releaseTime }}发布
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="listData" v-if="tabList.length == 1">
                            <div class="everyss" v-if="tabList[0].type == 3" v-for="(items, inde) in list2.list" :key="inde">
                                <div>
                                    <div class="every_list2" @click="goDetail(items.id)" v-if="inde < 6">
                                        <div class=" list_title">
                                            <div class="title_content">
                                                {{ items.title }}
                                            </div>
                                            <div class="title_money">
                                                <span class="mianyi" v-if="items.isFace == 1">面议</span>
                                                <span class="wage" v-else>{{ items.salaryMin }}~{{ items.salaryMax
                                                }}k</span>
                                            </div>
                                        </div>
                                        <div class="jianjie" v-if="inde < 6">
                                            <div class="everyContent1 ellipsis_3">
                                                {{ items.description }}
                                            </div>
                                        </div>
                                        <div class="last_bottom" v-if="inde < 6">
                                            <div class="gongsi">
                                                {{ items.companyName }}
                                            </div>
                                            <div class="shijian">
                                                {{ items.releaseTime }}发布
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="more" v-if="tabList.length == 2">
                    <el-button type="primary" v-if="tabCurrents == 0 && list1.list.length > 0"
                        @click="goRent">查看更多</el-button>
                    <el-button type="primary" v-if="tabCurrents == 1 && list2.list.length > 0"
                        @click="goRecruitment">查看更多</el-button>
                </div>
                <div class="more" v-if="tabList.length == 1">
                    <el-button type="primary" v-if="list1.list.length > 0" @click="goRent">查看更多</el-button>
                    <el-button type="primary" v-if="list2.list.length > 0" @click="goRecruitment">查看更多</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let id = 0;
import { banner } from "@/api/banner";
import house from "@/api/housedetail";
import mainpage from "@/api/mainpage";
import Card from "../components/Card/index.vue";
// import { EffectCube, Pagination } from "swiper";

export default {
    components: {
        Card,
    },
    data() {
        let that = this;
        return {
            // modules: [EffectCube, Pagination],
            preActiveNum: 0,
            imgList: [
                require("../assets/logo/logo5.jpg"),
                require("../assets/logo/logo3.jpg"),
                // require("../assets/logo/logo4.jpg"),
                require("../assets/logo/logo1.jpg"),
                require("../assets/logo/logo2.jpg"),
                require("../assets/logo/logo6.jpg"),
                require("../assets/logo/logo7.jpg"),
                require("../assets/logo/logo8.jpg"),
                require("../assets/logo/logo9.jpg"),
            ],
            activeName: 'first',
            index: 0,
            selectedOptions: [],
            navInputProps: {
                lazy: true,
                value: "id",
                label: "name",
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    house
                        .mapList({
                            showAll: 0,
                            pid: node.level === 0 ? null : node.data.id,
                        })
                        .then((res) => {
                            res.data.forEach((it) => {
                                it.leaf = level >= 2;
                            });
                            resolve(res.data);
                        });

                    // setTimeout(() => {
                    //   const nodes = Array.from({ length: level + 1 }).map((item) => ({
                    //     value: ++id,
                    //     label: `选项${id}`,
                    //     leaf: level >= 2,
                    //   }));
                    //   // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //   resolve(nodes);
                    // }, 1000);
                },
            },
            swiperOption: {
                effect: "cube",
                cubeEffect: {
                    slideShadows: false,
                    shadow: false,
                },
                grabCursor: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,
                // mousewheel: true,
                // loop: true,
                speed: 2000,
                autoplay: {
                    delay: 6 * 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false,
                },
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
                // 设置点击箭头
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // coverflowEffect: {
                //   rotate: 0, // 旋转的角度
                //   stretch: 100, // 拉伸   图片间左右的间距和密集度
                //   depth: 150, // 深度   切换图片间上下的间距和密集度
                //   modifier: 3, // 修正值 该值越大前面的效果越明显
                //   slideShadows: false, // 页面阴影效果
                // },
                on: {
                    init: function (swiper) {
                        that.isOver = true;
                    },
                    transitionStart: function () {
                        that.isOver = false;
                    },
                    transitionEnd: function () {
                        that.isOver = true;
                    },
                    // slideChangeTransitionStart() {
                    //   alert("111");
                    //   // setTimeout(() => {
                    //   that.isOver = false;
                    //   // }, 1000);
                    // },
                    // init() {
                    //   that.isOver = true;
                    //   // setTimeout(() => {
                    //   //   that.isOver = false;
                    //   // }, 3000);
                    // },

                    // slideChangeTransitionEnd() {
                    //   // alert("222");
                    //   // setTimeout(() => {
                    //   that.isOver = true;
                    //   // }, 2000);
                    // },
                },
            },
            isOver: false,
            swiperOptionVR: {
                slidesPerView: 3,
                slidesPerGroup: 6,
                // loop: true,
                // loopFillGroupWithBlank: true,
                spaceBetween: 60,
                slidesPerColumnFill: "row",
                slidesPerColumn: 2,

                paginationClickable: true,

                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //允许分页点击跳转
                },
            },
            swiperData: [],
            form: {
                province: "",
                provinceId: "",
                provinceOp: [],
                city: "",
                cityId: "",
                countyId: "",
                cityOp: [],
            },
            tabList: [{
                name: "仓库求租",
                type: 2,
                list: []
            },
            {
                name: "人才招聘",
                type: 3,
                list: []
            }
            ],
            tabCurrents: 0,
            param1: {
                type: 2,
                status: 2,
                upDown: 1,
                // uid: '',
                page: 1,
                viewType: 2
            },
            param2: {
                type: 3,
                status: 2,
                upDown: 1,
                // uid: '',
                page: 1,
                viewType: 2
            },
            list: {
                list: []
            },
            list1: {
                list: []
            },
            list2: {
                list: []
            },

            searchData: "",
            showActive: false,
            newData: [],
            digital: [],
            activeName: "0",
            one: true,
            two: true,
            three: true,
            four: true,
            wareHouseListVr: {},
            wareHouseList: [],
        };
    },
    watch: {
        activeName(cur, pre) {
            this.preActiveNum = pre;
        },
    },
    mounted() {
        this.get();
        // house.shareInfo().then((res) => {
        //   // console.log(0,res);
        // });
        // house
        //   .warehouseDetailById("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(111,res);
        //   });
        // house
        //   .warehouseRecommendList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(222,res);
        // });
        // house.commonCategoryList().then((res) => {
        //   console.log(333, res);
        // });
        house.mapList({ showAll: 1, pid: null }).then((res) => {
            this.form.provinceOp = res.data;
            this.cascaderArry = res.data;
            // console.log(444,res);
        });
        // house
        //   .warehouseBannerList("57606a41cd934e9da378d9724dd50c66")
        //   .then((res) => {
        //     // console.log(555,res);
        //   });
        // house
        //   .warehousePageInfo({
        //     pageNum: 1,
        //     pageSize: 10,
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //   })
        //   .then((res) => {
        //     // console.log(666,res);
        //   });
        // house
        //   .mapAreaAndWarehouseInfos({
        //     warehouseName: "",
        //     province: "",
        //     city: "",
        //     county: "",
        //     categoryIds: [],
        //     areaIds: ["120000"],
        //   })
        //   .then((res) => {
        //     // console.log(777,res);
        //   });
        this.wareHouseListVr = {};
        mainpage.homeRecommendlist().then((res) => {
            console.log(888, res);
            // this.wareHouseList = res.data

            // console.log("res.data", res);
            if (res.data == null) {
                return
            }
            const data = res.data.reverse();
            this.wareHouseList = data
            // this.wareHouseListVr = data.filter((item) => item.code == "vr")[0];
            // this.wareHouseList = data.filter((item) => item.code !== "vr");
            // console.log(this.wareHouseList);

            // res.data.forEach((it) => {
            //   // console.log(it);
            //   if (it.code == "vr") {
            //     // this.wareHouseListVr = it;
            //   } else {
            //     this.wareHouseList.push(it);
            //   }
            // });
        });
        // house.mapAreaAndWarehouseInfos().then((res)=>{
        //   console.log(11,res);
        // })
    },
    created() {
        this.rentList()
        // if (localStorage.getItem("token") != '' && localStorage.getItem("token") != undefined) {

        // }

    },
    methods: {
        async rentList() {
            let {
                param1,
                param2
            } = this;
            let newList1 = await this.$api.base.rentList(param1);
            console.log(newList1);
            for (var i = 0; i < newList1.list.length; i++) {
                newList1.list[i].rentBeginTime = this.$util.formatTime(parseInt(newList1.list[i].rentBeginTime),
                    "YY-M-D")
            }
            this.list1 = newList1

            // this.tabList[0].list = newList1.list
            let newList2 = await this.$api.base.rentList(param2);
            console.log(newList2);
            this.list2 = newList2
            for (var i = 0; i < newList2.list.length; i++) {
                newList2.list[i].releaseTime = this.$util.formatTime(newList2.list[i].releaseTime, "YY-M-D")
            }
            console.log(newList2.list);
            // this.tabList[1].list = newList2.list
            console.log();
            if (this.list1.list.length == 0 && this.list2.list.length == 0) {

                this.tabList = []
            } else {
                if (this.list1.list.length == 0) {
                    this.tabList = [
                        {
                            name: "人才招聘",
                            type: 3,
                            list: []
                        }
                    ]

                }
                if (this.list2.list.length == 0) {
                    this.tabList = [{
                        name: "仓库求租",
                        type: 2,
                        list: []
                    }
                    ]

                }
            }




        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        get() {
            banner().then((res) => {
                this.swiperData = res.data;
                // console.log(res);
            });
        },

        openNewTabs(url) {
            window.open(url);
        },

        goToMap(val) {
            // console.log("cityw", this.selectedOptions);

            if (val == "more") {
                return this.$router.push({
                    path: "/map",
                });
            }

            this.$router.push({
                path: "/map",
                query: {
                    name: this.searchData,
                    provinceId: this.selectedOptions[0],
                    cityId: this.selectedOptions[1],
                    countyId: this.selectedOptions[2],
                },
            });
        },
        goRecruitment(val) {
            this.$router.push({
                path: "/recruitment",
                query: {

                },
            });
        },
        goDetail(id) {
            this.$router.push({
                path: "/rentDetail",
                query: {
                    type: "rec",
                    id: id
                },
            });
        },
        goRentDetail(id) {
            this.$router.push({
                path: "/rentDetail",
                query: {
                    type: "ask",
                    id: id
                },
            });
        },
        goRent() {
            this.$router.push({
                path: "/askforrent",
                query: {
                    type: 1
                },
            });
        },

        showHouses(it) {
            this.showActive = it === 1 ? true : false;
            if (it) {
                // console.log("housedetail", house);
            } else {
            }
        },
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        mouseIn1(t) {
            this.one = false;
        },
        mouseIn2(t) {
            this.two = false;
        },
        mouseIn3(t) {
            this.three = false;
        },
        mouseIn4(t) {
            this.four = false;
        },
        mouseLeave1(t) {
            this.one = true;
        },
        mouseLeave2(t) {
            this.two = true;
        },
        mouseLeave3(t) {
            this.three = true;
        },
        mouseLeave4(t) {
            this.four = true;
        },
    },
};
</script>
<style scoped>
.more {}

.ellipsis_1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.ellipsis_3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

/deep/ .v-modal {
    z-index: 1000 !important;
}

.listData {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.everyss {

    width: 45%;
    padding: 0 20px;
}

.every_list {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    /* padding: 20px; */
    margin-bottom: 30px;
    justify-content: space-between;
}

.every_list2 {
    /* width: 45%; */
    margin-bottom: 60px;
    /* padding: 20px; */
    margin-bottom: 30px;
}

.list_title {
    height: 24px;
    width: 80%;
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    display: -webkit-box;
    /* -webkit-box-orient: vertical; */
    /* -webkit-line-clamp: 1; */
    /* overflow: hidden; */

    .title_content {
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

    }

}

.title_money {}

.mianyi {
    color: #D00000;
}

.wage {
    color: #09B37E;
}

.list_content {
    display: flex;
    flex-wrap: wrap;

}

.every_content {
    /* background-color: #999999; */
    width: 90%;
}

.everyContent {
    width: 45%;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 29px;
}

.everyContents {
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 29px;
}

.everyContent1 {
    width: 80%;
    font-size: 16px;
    height: 60px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 29px;
    margin-bottom: 20px;
}

.last_bottom {
    width: 80%;
    display: flex;
    justify-content: space-between;


}

.gongsi,
.shijian {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #CCCCCC;
}

.list_ico img {
    width: 24px;
    height: 24px;
}

img {
    position: relative;
    z-index: 0;
}

.el-o {
    font-weight: 500;
}

.firstActive {
    animation: firstActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
    width: 100%;
}

@keyframes firstActive {
    0% {
        transform: translateX(-500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.secondActive {
    animation: secondActive 0.5s;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    width: 100%;
    /*Safari and Chrome*/
}

@keyframes secondActive {
    0% {
        transform: translateX(500px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.tAsBox {
    animation: fadenum 2s;
    display: block !important;
    animation-iteration-count: 1;
    -webkit-animation-iteration-count: 1;
    /*Safari and Chrome*/
}

@keyframes fadenum {
    0% {
        opacity: 0;
        /* transform: translateY(70px); */
        top: 300px;
    }

    100% {
        opacity: 1;
        top: 250px;
    }
}

.w11 {
    padding: 50px 0 0px 0;
}

.slide-swiper {
    /* width: 357px !important; */
    height: 257px;
    margin-top: 60px;
    /* margin-left: 64px; */
}

.slide-swiper:first-child {
    margin-left: 0;
}

.slide-swiper .card {
    width: 357px !important;
    height: 257px !important;
}

/deep/.slide-swiper .card .card-box {
    width: 357px !important;
    height: 257px !important;
}

.vr-swiper .swiper-container {
    padding-bottom: 76px;
}

.flexws-box {

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexws-box .warehouse-card {
    /* min-height: 476px; */
}
</style>
